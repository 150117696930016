<app-nav-bar (emitNewUnit)="addUnit($event)" [sensorTypes]="sensorTypes"></app-nav-bar>

<div class="container dashboard">
  <p-accordion>
      <p-accordionTab *ngFor="let unit of units">
        <p-header [className]="'dashboard-unit-wrapper'">
          <div [className]="'row dashboard-unit'">
            <div class="col-sm-5 col-md-7 col-xl-9"><h3 class="unitName">{{ unit.unit.description}}</h3></div>
            <div class="col-sm-7 col-md-5 col-xl-3 dashboard-unit-heading">
              <button pButton type="button" label="Sensors graph" class="p-button-primary" icon="pi pi-chart-line" [id]="unit.unit.description"  [routerLink]="['/dashboard/unit', unit.unit.unitId]" [queryParams]="{unitDescription: unit.unit.description}"></button>
              <div class="dashboard-button-separator"></div>
              <button pButton type="button" class="p-button-warning" icon="pi pi-cog" [id]="'manipulation_'+unit.unit.unitId"  (click)="showItems($event, unit.unit); menu.toggle($event)"></button>
              <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'" [baseZIndex]="50"></p-menu>
            </div>
          </div>
        </p-header>
        <div>
          <ng-container *ngFor="let sensor of unit.sensors; let i = index">
            <ng-container *ngIf="i > 0 && unit.sensors[i].sensorId.toString().slice(0, 5) !== unit.sensors[i-1].sensorId.toString().slice(0, 5)">
              <hr>
            </ng-container>
            <app-sensors *ngIf="sensor" [sensor]="sensor" [unit]="unit.unit" [phenomenons]="phenomenons" [loggedUser]="loggedUser" (emitSensorDeletion)="deleteSensor(unit.unit.unitId, $event)" [sensorTypes]="sensorTypes"></app-sensors>
          </ng-container>
        </div>
      </p-accordionTab>
  </p-accordion>
</div>

<app-unit-popup *ngIf="showEditUnitPopup" [(isVisible)]="showEditUnitPopup" [unit]="editedUnit"></app-unit-popup>
<app-sensor-insert-popup *ngIf="showInsertSensorPopup" [unit]="editedUnit" [(isVisible)]="showInsertSensorPopup" [phenomenons]="phenomenons" [sensorTypes]="sensorTypes" (emitNewSensor)="addSensors($event)"></app-sensor-insert-popup>
<app-position-insert-popup *ngIf="showInsertPositionPopup" [unitId]="editedUnit.unitId" [(isVisible)]="showInsertPositionPopup"></app-position-insert-popup>


<p-confirmDialog key="positionDialog" [position]="position" [baseZIndex]="10000" rejectButtonStyleClass="p-button" [className]="'popup-form confirm'" [closable]="false"></p-confirmDialog>
