import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, mapTo, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {UserState} from '../states/user.state';
import {LoginService} from '../../shared/api/endpoints/services/login.service';
import {User} from '../models/user';
import {GlobalVariable} from '../../globals';
import {UserCookie} from '../../shared/api/endpoints/models/user-cookie';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private userState: UserState,
    private router: Router,
    private cookieService: CookieService,
    private loginService: LoginService
  ) {
  }

  /**
   * Set state from saved cookies
   */
  setFromCookie() {
    const user: User = {
      userCookie: {
        rightsID: JSON.parse(localStorage.getItem(GlobalVariable.RIGHTS)),
        language: this.cookieService.get(GlobalVariable.LANGUAGE),
        audio: JSON.parse(this.cookieService.get(GlobalVariable.AUDIO)),
        sessionid: this.cookieService.get(GlobalVariable.SESSION_ID)
      },
      isLoggedIn: true
    }
    this.userState.setUser(user);
  }

  /**
   * Get user from state
   */
  getUser() {
    console.log('Auth Service');
    if (!this.userState.getUser() && this.cookieService.get(GlobalVariable.SESSION_ID)) {
      console.log('Session in cookie!');
      this.setFromCookie();
    }
    return this.userState.getUser();
  }

  /**
   * Get user from state as observable
   */
  getUserState(): Observable<User> {
    return this.userState.getUser$();
  }

  /**
   * Login process with backend call
   * @param loginInput login form
   */
  doLogin(loginInput): Observable<boolean> {
    return this.loginService.login$Response(loginInput)
      .pipe(
        tap((userCookie: HttpResponse<UserCookie>) => this.setUserFromResponse(userCookie.body, loginInput.username)),
        mapTo(true),
        catchError(() => {
          return of<boolean>(false);
        })
      );
  }

  /**
   * Set user state, cookie and localStorage from server response
   * @param userCookie server response
   * @param username form username
   */
  setUserFromResponse(userCookie: UserCookie, username): UserCookie {
    console.log('Setting user from login!');
    this.userState.setUser({
      userCookie,
      isLoggedIn: true
    });
    this.setSessionStorage(userCookie, username);
    return userCookie;
  }

  /**
   * Logout user, clear user state and redirect to login
   */
  doLogout() {
    this.userState.setUser(null);
    this.cookieService.deleteAll();
    localStorage.clear();
    this.router.navigate(['/login']);
    this.cookieService.deleteAll();
  }

  /**
   * Set localstorage and cookies
   * @param userCookie user obejct
   * @param username username from login form
   */
  setSessionStorage(userCookie: UserCookie, username) {
    localStorage.setItem(GlobalVariable.USER_NAME, username)
    localStorage.setItem(GlobalVariable.RIGHTS, userCookie.rightsID.toString());
    this.cookieService.set('user', username);
  }

  redirectToDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
