<app-nav-bar></app-nav-bar>

<div class="container graph">
  <div class="graph-information">
    <div class="graph-desc">
      <div>
        <span class="graph-attr-heading">Unit description: </span>
        {{unitDescription}}
      </div>
    </div>
    <div class="graph-range-dates">
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-color-date background-date-color"><i class="fa fa-calendar-alt" aria-hidden="false"></i></span>
        </div>
        <p-calendar id="from" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="from" [showTime]="true" (onSelect)="aggregationShow()" [maxDate]="today" showButtonBar="true"></p-calendar>
      </div>
      <div class="graph-range-dates-separator">
        <div></div>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-color-date background-date-color"><i class="fa fa-calendar-alt" aria-hidden="false"></i></span>
        </div>
        <p-calendar id="to" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="to" [showTime]="true" (onSelect)="aggregationShow()" [maxDate]="today" showButtonBar="true"></p-calendar>
      </div>
      <p-listbox *ngIf="showAggregation" [options]="aggregationFunction" [(ngModel)]="selectedAggregationFunction" optionLabel="name" optionValue="code"></p-listbox>
      <div>
        <button pButton type="button" *ngIf="dateChanged" label="Load data" class="p-button-primary" icon="pi pi-chart-line" (click)="showGraph()"></button>
      </div>
    </div>
  </div>
  <div class="graph-view-wrapper">
    <ng-container *ngFor="let group of sensorGroups">
      <div class="row">
        <div class="graph-group-heading">
          Group of
          <ng-container *ngFor="let sensorType of sensorTypes">
            <ng-container *ngIf="sensorType.sensorId.toString().slice(0, 5) === group">
              {{sensorType.sensorType}}
            </ng-container>
          </ng-container>
          sensors:
        </div>
        <ng-container *ngFor="let sensor of sensors">
          <div *ngIf="sensor.sensorId.toString().slice(0, 5) === group" class="p-field-checkbox">
            <p-checkbox [id]="sensor.sensorId" name="{{group}}" [value]="sensor.sensorId.toString()" [(ngModel)]="selectedSensors" [inputId]="sensor.sensorId.toString()" (onChange)="addSensorToGraph(sensor.sensorId.toString(), $event)"></p-checkbox>
            <label>{{sensor.sensorName}}</label>
          </div>
        </ng-container>
      </div>
      <div id="vega_container_{{group}}"></div>
    </ng-container>
  </div>
</div>
