/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Group } from '../models/group';


/**
 * Groups endpoints
 */
@Injectable({
  providedIn: 'root',
})
export class GroupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getGroups
   */
  static readonly GetGroupsPath = '/senslog15/GroupService';

  /**
   * Get groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroups$Response(params: {

    /**
     * Group operation
     */
    Operation: 'GetGroups' | 'GetSuperGroups' | 'GetSubGroups';
    parent_group?: number;
  }): Observable<StrictHttpResponse<Array<Group>>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetGroupsPath, 'get');
    if (params) {
      rb.query('Operation', params.Operation, {});
      rb.query('parent_group', params.parent_group, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Group>>;
      })
    );
  }

  /**
   * Get groups.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroups(params: {

    /**
     * Group operation
     */
    Operation: 'GetGroups' | 'GetSuperGroups' | 'GetSubGroups';
    parent_group?: number;
  }): Observable<Array<Group>> {

    return this.getGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Group>>) => r.body as Array<Group>)
    );
  }

}
