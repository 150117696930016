<p-dialog [visible]="isVisible" [modal]="true" [closable]="false" [draggable]="false" header="Add sensor to unit&nbsp;&ndash;&nbsp;{{unit?.unitId}}"
          [baseZIndex]="10000" (onShow)="clearFormArray()" [className]="'popup-form'">
  <form [formGroup]="insertForm">
  <div class="input-group form-group popup-sensors-wrapper">
    <div formArrayName="sensors" *ngFor="let item of insertForm.get('sensors')['controls']; let i = index;">
      <div [formGroupName]="i" class="popup-sensor">
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-list-ol"></i></span>
          </div>
          <input type="text" formControlName="sensorId" class="form-control" id="sensorId" placeholder="Sensor ID"/>
        </div>
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-file-signature"></i></span>
          </div>
          <input type="text" formControlName="sensorName" class="form-control" id="sensorName"
                 placeholder="Sensor name"/>
        </div>
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-file-signature"></i></span>
          </div>
          <select formControlName="sensorType" id="sensorType">
            <option value="null" disabled>Select sensor type</option>
            <option *ngFor="let sensorType of sensorTypes; let i = index" [value]="sensorTypes[i].sensorType">
              {{sensorTypes[i].sensorType}}
            </option>
          </select>
        </div>
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-tint"></i></span>
          </div>
          <select formControlName="phenomenons" id="phenomenons">
            <option value="null" disabled>Select phenomenon</option>
            <option *ngFor="let phenomenon of phenomenons; let i = index" [value]="phenomenons[i].phenomenonId">
              {{phenomenons[i].phenomenonName}} ( {{ phenomenons[i].unit}})
            </option>
          </select>
        </div>
      </div>
      <br>
    </div>
  </div>
  </form>
  <p-footer>
    <div class="popup-buttons-wrapper">
      <div class="popup-buttons-group">
        <button pButton type="button" label="Add sensor" class="p-button-success" (click)="addSensor()"></button>
        <button pButton type="button" label="Remove last sensor" class="p-button-danger" *ngIf="sensors > 1" (click)="removeSensor()"></button>
      </div>
      <div class="popup-buttons-group">
        <button pButton type="button" label="Close" class="p-button-primary dark" icon="pi pi-times" (click)="close()"></button>
        <button pButton type="submit" label="Save" class="p-button-primary dark" icon="pi pi-check" (click)="processSensorInsertion()"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
