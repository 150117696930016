/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Right } from '../models/right';
import { UserInfo } from '../models/user-info';


/**
 * Administration service
 */
@Injectable({
  providedIn: 'root',
})
export class AdministrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createUser
   */
  static readonly CreateUserPath = '/senslog15/rest/user';

  /**
   * Create User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: {
    body: UserInfo
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdministrationService.CreateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create User.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: {
    body: UserInfo
  }): Observable<void> {

    return this.createUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRights
   */
  static readonly GetRightsPath = '/senslog15/rest/user/rights';

  /**
   * Get rights.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRights()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRights$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Right>>> {

    const rb = new RequestBuilder(this.rootUrl, AdministrationService.GetRightsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Right>>;
      })
    );
  }

  /**
   * Get rights.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRights$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRights(params?: {
  }): Observable<Array<Right>> {

    return this.getRights$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Right>>) => r.body as Array<Right>)
    );
  }

}
