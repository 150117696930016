import {Graph} from "./graph";

declare var require: any

/**
 * A graph with no sensors to display
 */
export class EmptyGraph implements Graph{

  /**
   * Message in the middle of the graph
   */
  message: string;

  constructor(message) {
    this.message = message;
  }

  /**
   * Returns vega configuration
   */
  getConfig(): {} {
    return require('/src/vega/config/config.json');
  }

  /**
   * Returns vega specification
   */
  getSpec(): {} {
    const lodash = require('lodash/object');
    const rvalue: any = {};
    let base = require('/src/vega/base/empty.json');
    lodash.merge(rvalue, base);
    rvalue.signals[0].value = this.message;
    return rvalue;
  }

}
