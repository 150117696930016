import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarComponent} from './components/nav-bar.component';
import {RouterModule} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import { UserInsertPopupComponent } from './components/user-insert-popup/user-insert-popup.component';
import {DialogModule} from 'primeng/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {UnitInsertPopupComponent} from './components/unit-insert-popup/unit-insert-popup.component';


@NgModule({
  declarations: [NavBarComponent, UserInsertPopupComponent, UnitInsertPopupComponent],
  exports: [
    NavBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule
  ]
})
export class NavBarModule {
}
