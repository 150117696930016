import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/components/login.component';
import {DashboardComponent} from './dashboard/components/dashboard.component';
import {AuthGuard} from './auth/guards/auth.guard';
import {RoleGuard} from './auth/guards/role.guard';
import {SensorComponent} from './sensor/components/sensor.component';
import {UnitComponent} from './unit/components/unit.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    canActivate: [AuthGuard],
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full'
  }, {
    canActivate: [AuthGuard, RoleGuard],
    path: 'dashboard/unit/:unitId/sensor/:sensorId',
    component: SensorComponent,
    pathMatch: 'full',
    data: {
      expectedRole: ['1', '0']
    }
  }, {
    canActivate: [AuthGuard, RoleGuard],
    path: 'dashboard/unit/:unitId',
    component: UnitComponent,
    pathMatch: 'full',
    data: {
      expectedRole: ['1', '0']
    }
  }, {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
