import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {CookieService} from 'ngx-cookie-service';
import {GlobalVariable} from '../../globals';
import {ToastService} from '../../shared/services/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private toastService: ToastService
  ) {
  }

  /**
   * Intercept every request to backend
   * @param request request
   * @param next next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // check if data in cookies
    if (!this.cookieService.get(GlobalVariable.SESSION_ID) && !request.url.includes('ControllerServlet')) {
      console.log('No sessionid!');
      this.cookieService.deleteAll();
      this.authService.doLogout();
      return of(null);
    }

    console.log('Sending request!', request.url);
    return next.handle(request)
      .pipe(
        catchError(err => {
          if ((err instanceof HttpErrorResponse && err.status === 502 || err.status === 401 ||
            err.status === 0)) {
            console.log(err);
            this.toastService.showError(err.error.message);
            return this.handleError(request, next);
          } else if ((err instanceof HttpErrorResponse && err.status === 500 || err.status === 504 || err.status === 500)) {
            if (err.error) {
              this.toastService.showError(err.error);
            } else {
              this.toastService.showError('500 - Error!');
            }
            this.handle500Error(err);
          } else {
            return throwError(err);
          }
        })
      );
  }

  handleError(request: HttpRequest<any>, next: HttpHandler) {
    this.authService.doLogout();
    return of(null);
  }

  handle500Error(err: any) {
    this.authService.redirectToDashboard();
    return of(null);
  }
}
