<div class="row">
  <div class="col-sm-7 col-md-8 col-lg-9">
    <div class="dashboard-sensor-heading-wrapper">
      <h4>{{ sensor.sensorName }}</h4>
    </div>
  </div>
  <div class="col-sm-5 col-md-4 col-lg-3 dashboard-sensor-heading">
    <button pButton type="button" label="View graph" class="p-button-primary" [id]="sensor.sensorName" [routerLink]="['/dashboard/unit', unit.unitId, 'sensor', sensor.sensorId]" [queryParams]="{unitName: unit.description}" title="Sensor" icon="pi pi-chart-line"></button>
    <ng-container *ngIf="loggedUser?.userInfo?.rightsId == 0 || loggedUser?.userInfo?.rightsId == 1">
      <div class="dashboard-button-separator"></div>
      <p-button [id]="'manipulation_sensor_'+sensor.sensorId" icon="pi pi-cog" styleClass="p-button-warning" (click)="showItems($event, sensor); menu.toggle($event)"></p-button>
      <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'" [baseZIndex]="50"></p-menu>
    </ng-container>
  </div>
</div>

<app-sensor-popup *ngIf="editedSensor" [(isVisible)]="showSensorPopup" [sensor]="editedSensor" [phenomenons]="phenomenons" [unitId]="unit.unitId" [sensorTypes]="sensorTypes"></app-sensor-popup>
