<p-dialog [visible]="isVisible" [modal]="true" [closable]="false" [draggable]="false" header="Edit unit - {{ unit?.unitId}}" [baseZIndex]="10000" [className]="'popup-form'">
  <form [formGroup]="insertForm">
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-pen"></i></span>
      </div>
      <input type="text" formControlName="unitDescription" class="form-control" id="unitDescription" placeholder="{{unit?.description}}"/>
    </div>
  </form>
  <p-footer>
    <div class="pupup-buttons">
      <button pButton type="button" label="Close" class="p-button-primary dark" icon="pi pi-times" (click)="close()"></button>
      <button pButton type="submit" label="Save" class="p-button-primary dark" icon="pi pi-check" (click)="saveUnit()"></button>
    </div>
  </p-footer>
</p-dialog>
