import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment-timezone';
import {AnalyticsService} from '../../shared/api/endpoints/services/analytics.service';
import {AggregationModel} from '../../shared/models/aggregationModel';
import {GraphLoader} from '../../shared/graph-loading/graphloader';
import {ObservationService} from '../../shared/api/endpoints/services/observation.service';
import {HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ToastService} from '../../shared/services/toast.service';
import {Sensor} from '../../shared/api/endpoints/models/sensor';
import {SensorsService} from '../../shared/api/endpoints/services/sensors.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss']
})
export class SensorComponent implements OnInit, OnDestroy {

  sensorId: number;
  unitId: number;
  data = [];
  time = [];
  from: Date = moment().hour(0).minutes(0).subtract(7, 'days').toDate();
  to: Date = moment().toDate();
  showAggregation = false;
  aggregationFunction: AggregationModel[];
  selectedAggregationFunction = 'HOUR';
  sensor: Sensor;
  dateChanged = false;
  unitDescription: string;
  today: Date = moment().toDate();
  subscription: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private observationService: ObservationService,
    private toastService: ToastService,
    private sensorsService: SensorsService,
    private route: ActivatedRoute,
  ) {
    this.getInitData();
    this.sensorsService.getUnitSensors({unit_id: this.unitId}).subscribe(sensors => {
      if (sensors) {
        this.sensor = sensors.filter(value => value.sensorId === this.sensorId)[0];
        this.showGraph(); // show default graph
      }
    }, err => this.toastService.showError(err.error.message));
  }

  /**
   * Sets up default data
   */
  getInitData() {
    this.route.queryParams.subscribe(params => {
      if(params.unitName)  {
        this.unitDescription = params.unitName;
      }
    });
    this.sensorId = parseInt(this.activatedRoute.snapshot.paramMap.get('sensorId'), 10);
    this.unitId = parseInt(this.activatedRoute.snapshot.paramMap.get('unitId'), 10);
    this.aggregationFunction = [
      {name: 'Hour', code: 'HOUR'},
      {name: 'Day', code: 'DAY'},
      {name: 'Month', code: 'MONTH'},
      {name: 'Year', code: 'YEAR'}
    ];
  }

  /**
   * Unsubscribe after leaving
   */
  ngOnDestroy(): void {
    this.subscription.forEach(subs => subs.unsubscribe());
  }

  ngOnInit(): void {
  }

  /**
   * Shows aggregation select box and get data button
   */
  aggregationShow() {
    this.dateChanged = true;
    this.showAggregation = moment(this.to).diff(moment(this.from), 'days') > 7;
  }

  /**
   * Gets data based on selected time range
   */
  showGraph() {
    if (moment(this.to).diff(moment(this.from), 'days') > 7) {
      this.showAggregation = true;
      const range: Date[] = [this.from, this.to];
      this.getAnalytics(range);
    } else {
      this.showAggregation = false;
      const range: Date[] = [this.from, this.to];
      this.getObservations(range);
    }
  }

  /**
   * Get data from analytics endpoint
   * @param range from and to interval
   */
  getAnalytics(range: Date[]) {
    this.analyticsService.getAnalytics$Response({
      unit_id: this.unitId, sensor_id: this.sensorId,
      from: moment(range[0]).format('yyyy-MM-DD HH:mm:ssZ').slice(0, -3),
      to: moment(range[1]).format('yyyy-MM-DD HH:mm:ssZ').slice(0, -3), interval: this.selectedAggregationFunction
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return response.body;
        } else if (response.status === 204) {
          this.toastService.showWarningNoData();
          return response.body;
        } else {
          return false;
        }
      })
    ).subscribe(
      observations => {
        if (observations) {
          GraphLoader.getGraphWithInterval(this.sensorId, observations[this.sensorId].data, observations[this.sensorId].interval * 1000, this.sensor, '#view', true);
        } else {
          GraphLoader.getGraph(null, null, null, '#view', null);
        }
      }, err => this.toastService.showError(err.error.message));
  }

  /**
   * Get data from observation endpoint
   * @param range from and to interval
   */
  getObservations(range: Date[]) {
    this.observationService.getObservation$Response({
      unit_id: this.unitId,
      sensor_id: this.sensorId,
      from: moment(range[0]).format('yyyy-MM-DD HH:mm:ssZ').slice(0, -3),
      to: moment(range[1]).format('yyyy-MM-DD HH:mm:ssZ').slice(0, -3)
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return response.body;
        } else if (response.status === 204) {
          this.toastService.showWarningNoData();
          return response.body;
        } else {
          return false;
        }
      })
    ).subscribe(
      observations => {
        if (observations) {
          GraphLoader.getGraph(this.sensorId, observations, this.sensor, '#view', false);
        } else {
          GraphLoader.getGraph(null, null, null,'#view', null);
        }
      }, err => this.toastService.showError(err.error.message));
  }
}
