import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard.component';
import {NavBarModule} from '../shared/nav-bar/nav-bar.module';
import {RouterModule} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import {AccordionModule} from 'primeng/accordion';
import { SensorsComponent } from './components/sensors/sensors.component';
import { UnitPopupComponent } from './components/unit-popup/unit-popup.component';
import {DialogModule} from 'primeng/dialog';
import { SensorPopupComponent } from './components/sensor-popup/sensor-popup.component';
import {ReactiveFormsModule} from '@angular/forms';
import { SensorInsertPopupComponent } from './components/sensor-insert-popup/sensor-insert-popup.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {SplitButtonModule} from 'primeng/splitbutton';
import { PositionInsertPopupComponent } from './components/position-insert-popup/position-insert-popup.component';
import {MenuModule} from 'primeng/menu';



@NgModule({
  declarations: [DashboardComponent, SensorsComponent, UnitPopupComponent, SensorPopupComponent, SensorInsertPopupComponent,
    PositionInsertPopupComponent],
    imports: [
        CommonModule,
        NavBarModule,
        RouterModule,
        ButtonModule,
        AccordionModule,
        DialogModule,
        ReactiveFormsModule,
        ConfirmDialogModule,
        SplitButtonModule,
        MenuModule
    ]
})
export class DashboardModule { }
