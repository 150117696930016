import {Component} from '@angular/core';
import {faCoffee} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet><p-toast [baseZIndex]="20000" position="bottom-center" key="mainToast"></p-toast>`
})
export class AppComponent {
  title = 'SensLog';
  faCoffee = faCoffee;
}
